// Libs
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

import ReactMarkdown from 'react-markdown';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import BreadCrumb from 'components/breadCrumb';
import SEO from 'components/seo';

//context
import { InternacionalizationContext } from 'context/internacionalization';

//Helper
import { getMarkdown } from '../../utils/getMarkdown';

// Styled
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  h1 {
    font-size: 3.375rem;
    color: #231f20;
    width: 35.625rem;
    margin-bottom: 0.625rem;

    @media ${device.laptop} {
      width: 9.1875rem;
      font-size: 1.5rem;
      margin-bottom: 0.875rem;
    }
  }

  a {
    color: #45a7df;
  }

  ul {
    list-style: none;
  }

  h2 {
    color: #231f20;
    font-size: 1.5rem;

    @media ${device.laptop} {
      font-size: 1.125rem;
    }
  }

  h6 {
    color: #8f9194;
    font-size: 1.875rem;

    @media ${device.mobile} {
      font-size: 1.5rem;
    }
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  margin-bottom: 2.5rem;

  :first-child {
    > :nth-child(3) {
      color: #8f9194;
    }
  }

  @media ${device.laptop} {
    margin-top: 3rem;
    flex-direction: column;
    margin-bottom: 0;
  }

  @media ${device.mobile} {
    margin-top: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: baseline;

  p {
    font-size: 1rem;
    color: #231f20;
    line-height: 1.625rem;
    padding-bottom: 1.875rem;

    @media ${device.laptop} {
      font-size: 0.875rem;
      line-height: 1.5rem;
    }

    > strong {
      color: #231f20;
    }

    img {
      width: 100%;
    }
  }

  > ul {
    margin-bottom: 2rem;

    li {
      :first-child {
        margin: 0 0 1.25rem 0;
      }

      display: flex;
      margin: 1.25rem 0;
      line-height: 26px;
      justify-content: space-around;

      @media ${device.mobile} {
        justify-content: space-between;
      }

      em {
        width: 95%;
        display: flex;
        text-align: left;

        @media ${device.laptop} {
          font-size: 0.875rem;
          line-height: 1.376rem;
        }
      }
    }
  }

  ol {
    color: #45a7df;
    cursor: pointer;
    line-height: 1.6rem;

    li:nth-child(10),
    li:nth-child(11) {
      margin-left: 0.5rem;
    }
  }
`;

const renderPage = (page, isDesktop, language) => {
  const teste = page && page[0].fragments.map(item => item);
  // const breadCrumb = teste[1].localizations[0].markdown;
  // const markdown = teste[0].localizations[0].markdown;
  // const markdownContent = teste[2].localizations[0].markdown;

  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={' 2 / 14'}>
        {/* <BreadCrumb markdown={breadCrumb} marginTop /> */}
        <BreadCrumb
          markdown={getMarkdown(
            page,
            'ckmayrv2olo430a67kxfap3do',
            true,
            false,
            language
          )}
          marginTop
        />
        <ReactMarkdown
          children={getMarkdown(
            page,
            'ckmayp8xslnrk0a677yz2swny',
            true,
            false,
            language
          )}
        />
        <ReactMarkdown
          children={getMarkdown(
            page,
            'ckmb00jvcmhej0b19b4zwjkqn',
            true,
            false,
            language
          )}
        />
      </Part>
    </GridContainer>
  );
};

const PrivacyPolicy = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);
  const { language, setShowSelect } = useContext(InternacionalizationContext);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  useEffect(() => {
    setShowSelect(true);
    return () => setShowSelect(false);
  }, []);

  return (
    <Layout>
      <SEO dataSeo={page} />
      {renderPage(page, isDesktop, language)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              id
              pages(where: { id: "ckmayoqf4lmfv0920r4wy55lo" }) {
                fragments(locales: [en, es, pt_BR]) {
                  id
                  name
                  localizations {
                    locale
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                  assetpickerMultiple {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <PrivacyPolicy page={response.gcms.site.pages} location={location} />
        );
      }}
    />
  );
};
